export default {
  data ({ data }) {
    return data
  },
  errors ({ validationErrors }) {
    return validationErrors
  },
  isDeleting ({ deleting }) {
    return deleting
  },
  isLoading ({ loading }) {
    return loading
  },
  isSaving ({ saving }) {
    return saving
  },
  pagination ({ page }) {
    return page
  },
  single ({ single }) {
    return single
  },

  // Customer View Stuff
  customerNotes ({ customerViewData }) {
    return customerViewData.notes
  },
  customerOrders ({ customerViewData }) {
    return customerViewData.orders
  },
  customerSupport ({ customerViewData }) {
    return customerViewData.support
  },
  customerCorrespondence ({ customerViewData }) {
    return customerViewData.correspondence
  },
  customerReminders ({ customerViewData }) {
    return customerViewData.reminders
  },
  customerActivity ({ customerViewData }) {
    return customerViewData.activity
  },
}
