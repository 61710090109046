import moment from 'moment'
import pagination from '../../utils/pagination'
import {
  CUSTOMERS_BATCH_DELETE_REQUEST, CUSTOMERS_BATCH_DELETE_SUCCESS, CUSTOMERS_BATCH_DELETE_FAILURE,
  CUSTOMERS_FETCH_MANY_REQUEST, CUSTOMERS_FETCH_MANY_SUCCESS, CUSTOMERS_FETCH_MANY_FAILURE,
  CUSTOMERS_FETCH_REQUEST, CUSTOMERS_FETCH_SUCCESS, CUSTOMERS_FETCH_FAILURE,
  CUSTOMERS_SAVE_REQUEST, CUSTOMERS_SAVE_SUCCESS, CUSTOMERS_SAVE_FAILURE,
  
  CUSTOMER_UPDATE_LAST_CONTACTED_AT,
  
  EMPTY_CUSTOMERS,
  CLEAR_ERRORS,

  CUSTOMER_NOTES_FETCH_REQUEST, CUSTOMER_NOTES_FETCH_SUCCESS, CUSTOMER_NOTES_FETCH_FAILURE,
  CUSTOMER_ORDERS_FETCH_REQUEST, CUSTOMER_ORDERS_FETCH_SUCCESS, CUSTOMER_ORDERS_FETCH_FAILURE,
  CUSTOMER_SUPPORT_FETCH_REQUEST, CUSTOMER_SUPPORT_FETCH_SUCCESS, CUSTOMER_SUPPORT_FETCH_FAILURE,
  CUSTOMER_CORRESPONDENCE_FETCH_REQUEST, CUSTOMER_CORRESPONDENCE_FETCH_SUCCESS, CUSTOMER_CORRESPONDENCE_FETCH_FAILURE,
  CUSTOMER_REMINDERS_FETCH_REQUEST, CUSTOMER_REMINDERS_FETCH_SUCCESS, CUSTOMER_REMINDERS_FETCH_FAILURE,
  CUSTOMER_ACTIVITY_FETCH_REQUEST, CUSTOMER_ACTIVITY_FETCH_SUCCESS, CUSTOMER_ACTIVITY_FETCH_FAILURE,
} from './types'

export default {
  [CUSTOMERS_BATCH_DELETE_REQUEST] (state) {
    state.deleting = true
  },
  [CUSTOMERS_BATCH_DELETE_SUCCESS] (state) {
    state.deleting = false
  },
  [CUSTOMERS_BATCH_DELETE_FAILURE] (state) {
    state.deleting = false
  },
  [CUSTOMERS_FETCH_MANY_REQUEST] (state) {
    state.data = []
    state.loading = true
  },
  [CUSTOMERS_FETCH_MANY_SUCCESS] (state, { data }) {
    if(Object.keys(data).includes('data')) {
      state.data = data.data
      state.page = pagination(data)
    }else{
      state.data = data
    }
    state.loading = false
  },
  [CUSTOMERS_FETCH_MANY_FAILURE] (state, { validationErrors }) {
    state.validationErrors = validationErrors || {}
    state.loading = false
  },
  [CUSTOMERS_FETCH_REQUEST] (state) {
    state.single = {}
    state.loading = true
  },
  [CUSTOMERS_FETCH_SUCCESS] (state, { data }) {
    state.single = data
    state.loading = false
  },
  [CUSTOMERS_FETCH_FAILURE] (state) {
    state.loading = false
  },
  [CUSTOMERS_SAVE_REQUEST] (state) {
    state.data = []
    state.saving = true
  },
  [CUSTOMERS_SAVE_SUCCESS] (state) {
    state.saving = false
  },
  [CUSTOMERS_SAVE_FAILURE] (state, { validationErrors }) {
    state.validationErrors = validationErrors || {}
    state.saving = false
  },
  [CUSTOMER_UPDATE_LAST_CONTACTED_AT] (state, { customer }) {
    var customerIndex = state.data.findIndex(c => (c.id == customer))
    if(customerIndex >= 0) state.data[customerIndex].last_contacted_at = moment().toString()
  },
  [EMPTY_CUSTOMERS] (state) {
    state.data = []
    state.loading = false
    state.saving = false
  },
  [CLEAR_ERRORS] (state) {
    state.validationErrors = []
    state.loading = false
    state.saving = false
  },


  // CUSTOMER VIEW STUFF
  [CUSTOMER_NOTES_FETCH_REQUEST] (state) {
    state.customerViewData.notes.isLoading = true
  },

  [CUSTOMER_NOTES_FETCH_SUCCESS] (state, { data }) {
    state.customerViewData.notes.data = data
    state.customerViewData.notes.isLoading = false
  },

  [CUSTOMER_NOTES_FETCH_FAILURE] (state, { data }) {
    state.customerViewData.notes.data = []
    state.customerViewData.notes.isLoading = false
  },

  [CUSTOMER_ORDERS_FETCH_REQUEST] (state) {
    state.customerViewData.orders.isLoading = true
  },

  [CUSTOMER_ORDERS_FETCH_SUCCESS] (state, { data }) {
    state.customerViewData.orders.data = data
    state.customerViewData.orders.isLoading = false
  },

  [CUSTOMER_ORDERS_FETCH_FAILURE] (state) {
    state.customerViewData.orders.data = []
    state.customerViewData.orders.isLoading = false
  },

  [CUSTOMER_SUPPORT_FETCH_REQUEST] (state) {
    state.customerViewData.support.isLoading = true
  },

  [CUSTOMER_SUPPORT_FETCH_SUCCESS] (state, { data }) {
    state.customerViewData.support.data = data
    state.customerViewData.support.isLoading = false
  },

  [CUSTOMER_SUPPORT_FETCH_FAILURE] (state) {
    state.customerViewData.support.data = []
    state.customerViewData.support.isLoading = false
  },

  [CUSTOMER_CORRESPONDENCE_FETCH_REQUEST] (state) {
    state.customerViewData.correspondence.isLoading = true
  },

  [CUSTOMER_CORRESPONDENCE_FETCH_SUCCESS] (state, { data }) {
    state.customerViewData.correspondence.data = data
    state.customerViewData.correspondence.isLoading = false
  },

  [CUSTOMER_CORRESPONDENCE_FETCH_FAILURE] (state) {
    state.customerViewData.correspondence.data = []
    state.customerViewData.correspondence.isLoading = false
  },

  [CUSTOMER_REMINDERS_FETCH_REQUEST] (state) {
    state.customerViewData.reminders.isLoading = true
  },

  [CUSTOMER_REMINDERS_FETCH_SUCCESS] (state, { data }) {
    state.customerViewData.reminders.data = data
    state.customerViewData.reminders.isLoading = false
  },

  [CUSTOMER_REMINDERS_FETCH_FAILURE] (state) {
    state.customerViewData.reminders.data = []
    state.customerViewData.reminders.isLoading = false
  },

  [CUSTOMER_ACTIVITY_FETCH_REQUEST] (state) {
    state.customerViewData.activity.isLoading = true
  },

  [CUSTOMER_ACTIVITY_FETCH_SUCCESS] (state, { data }) {
    state.customerViewData.activity.data = data
    state.customerViewData.activity.isLoading = false
  },

  [CUSTOMER_ACTIVITY_FETCH_FAILURE] (state) {
    state.customerViewData.activity.data = []
    state.customerViewData.activity.isLoading = false
  },

}