import {
  CUSTOMERS_BATCH_DELETE_REQUEST,
  CUSTOMERS_BATCH_DELETE_SUCCESS,
  CUSTOMERS_BATCH_DELETE_FAILURE,
  CUSTOMERS_FETCH_MANY_REQUEST,
  CUSTOMERS_FETCH_MANY_SUCCESS,
  CUSTOMERS_FETCH_MANY_FAILURE,
  CUSTOMERS_FETCH_REQUEST,
  CUSTOMERS_FETCH_SUCCESS,
  CUSTOMERS_FETCH_FAILURE,
  CUSTOMERS_SAVE_REQUEST,
  CUSTOMERS_SAVE_SUCCESS,
  CUSTOMERS_SAVE_FAILURE,

  CUSTOMER_UPDATE_LAST_CONTACTED_AT,

  EMPTY_CUSTOMERS,
  CLEAR_ERRORS,

  CUSTOMER_NOTES_FETCH_REQUEST,
  CUSTOMER_NOTES_FETCH_SUCCESS,
  CUSTOMER_NOTES_FETCH_FAILURE,

  CUSTOMER_ORDERS_FETCH_REQUEST,
  CUSTOMER_ORDERS_FETCH_SUCCESS,
  CUSTOMER_ORDERS_FETCH_FAILURE,

  CUSTOMER_SUPPORT_FETCH_REQUEST,
  CUSTOMER_SUPPORT_FETCH_SUCCESS,
  CUSTOMER_SUPPORT_FETCH_FAILURE,

  CUSTOMER_CORRESPONDENCE_FETCH_REQUEST,
  CUSTOMER_CORRESPONDENCE_FETCH_SUCCESS,
  CUSTOMER_CORRESPONDENCE_FETCH_FAILURE,

  CUSTOMER_REMINDERS_FETCH_REQUEST,
  CUSTOMER_REMINDERS_FETCH_SUCCESS,
  CUSTOMER_REMINDERS_FETCH_FAILURE,

  CUSTOMER_ACTIVITY_FETCH_REQUEST,
  CUSTOMER_ACTIVITY_FETCH_SUCCESS,
  CUSTOMER_ACTIVITY_FETCH_FAILURE,
} from './types'

export default {
  deleteMany ({ commit }, { ids = [] }) {
    commit(CUSTOMERS_BATCH_DELETE_REQUEST)
    return window.axios.post('/customers', { _method: 'DELETE', ids }).then(response => {
      commit(CUSTOMERS_BATCH_DELETE_SUCCESS, response)
      return response
    }).catch(error => {
      commit(CUSTOMERS_BATCH_DELETE_FAILURE, error)
      throw error
    })
  },
  fetchMany ({ commit }, { query = '', page = 1, customerStatus = null, userId = null }) {
    commit(CUSTOMERS_FETCH_MANY_REQUEST)
    return window.axios.get('/customers', { params: { query, page, customerStatus, userId } }).then(response => {
      commit(CUSTOMERS_FETCH_MANY_SUCCESS, response)
      return response
    }).catch(error => {
      commit(CUSTOMERS_FETCH_MANY_FAILURE, error)
      throw error
    })
  },
  fetchManyMine ({ commit }, { query = '', page = 1, customerStatus = null }) {
    commit(CUSTOMERS_FETCH_MANY_REQUEST)
    return window.axios.get('/customers/mine', { params: { query, page, customerStatus } }).then(response => {
      commit(CUSTOMERS_FETCH_MANY_SUCCESS, response)
      return response
    }).catch(error => {
      commit(CUSTOMERS_FETCH_MANY_FAILURE, error)
      throw error
    })
  },
  fetchManyUnassigned ({ commit }, { query = '', page = 1, customerStatus = null }) {
    commit(CUSTOMERS_FETCH_MANY_REQUEST)
    return window.axios.get('/customers/unassigned', { params: { query, page, customerStatus } }).then(response => {
      commit(CUSTOMERS_FETCH_MANY_SUCCESS, response)
      return response
    }).catch(error => {
      commit(CUSTOMERS_FETCH_MANY_FAILURE, error)
      throw error
    })
  },
  fetchManyLeads({ commit }, { from, to }) {
    commit(CUSTOMERS_FETCH_MANY_REQUEST)
    return window.axios.get('/customers/leads', { params: { from, to } }).then(response => {
      commit(CUSTOMERS_FETCH_MANY_SUCCESS, response)
      return response
    }).catch(error => {
      commit(CUSTOMERS_FETCH_MANY_FAILURE, error)
      throw error
    })
  },
  fetch ({ commit }, id) {
    commit(CUSTOMERS_FETCH_REQUEST)
    return window.axios.get(`/customers/${id}`).then(response => {
      commit(CUSTOMERS_FETCH_SUCCESS, response)
      return response
    }).catch(error => {
      commit(CUSTOMERS_FETCH_FAILURE, error)
      throw error
    })
  },
  save ({ commit }, user) {
    const path = user.id ? `/customers/${user.id}` : '/customers'
    const method = user.id ? 'put' : 'post'

    commit(CUSTOMERS_SAVE_REQUEST)
    return window.axios[method](path, user).then(response => {
      commit(CUSTOMERS_SAVE_SUCCESS, response)
      return response
    }).catch(error => {
      if (error.response.status === 422) {
        commit(CUSTOMERS_SAVE_FAILURE, { validationErrors: error.response.data })
      } else {
        commit(CUSTOMERS_SAVE_FAILURE, { error })
      }
      throw error
    })
  },
  empty({ commit }) {
    commit(EMPTY_CUSTOMERS);
  },
  clearErrors({ commit }) {
    commit(CLEAR_ERRORS)
  },
  updateLastContactedAt({ commit }, customer) {
    commit(CUSTOMER_UPDATE_LAST_CONTACTED_AT, { customer })
  },
  getStatuses({ commit }) {
    commit(CUSTOMERS_FETCH_MANY_REQUEST)
    return window.axios.get('/customers/get-statuses').then(response => {
      commit(CUSTOMERS_FETCH_MANY_SUCCESS, response)
      return response
    }).catch(error => {
      commit(CUSTOMERS_FETCH_MANY_FAILURE, error)
      throw error
    })
  },
  doQueueEmail ({ commit }, { customer, data }) {
    commit(CUSTOMERS_SAVE_REQUEST)
    return window.axios.post(`queue-email/${customer}`, data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }).then(response => {
      commit(CUSTOMERS_SAVE_SUCCESS, response)
      return response
    }).catch(error => {
      if (error.response.status === 422) {
        commit(CUSTOMERS_SAVE_FAILURE, { validationErrors: error.response.data })
      } else {
        commit(CUSTOMERS_SAVE_FAILURE, { error })
      }
      throw error
    })
  },

  // CUSTOMER VIEW STUFF
  fetchCustomerNotes({ commit }, customer) {
    commit(CUSTOMER_NOTES_FETCH_REQUEST)
    window.axios.get(`/customers/${customer}/notes`)
      .then(response => {
        commit(CUSTOMER_NOTES_FETCH_SUCCESS, response)
      }).catch(error => {
        commit(CUSTOMER_NOTES_FETCH_FAILURE, error)
        throw error
      })
  },
  
  fetchCustomerOrders({ commit }, customer) {
    commit(CUSTOMER_ORDERS_FETCH_REQUEST)
    window.axios.get(`/customers/${customer}/orders`)
      .then(response => {
        commit(CUSTOMER_ORDERS_FETCH_SUCCESS, response)
      }).catch(error => {
        commit(CUSTOMER_ORDERS_FETCH_FAILURE, error)
        throw error
      })
  },
  
  fetchCustomerSupport({ commit }, customer) {
    commit(CUSTOMER_SUPPORT_FETCH_REQUEST)
    window.axios.get(`/customers/${customer}/support`)
      .then(response => {
        commit(CUSTOMER_SUPPORT_FETCH_SUCCESS, response)
      }).catch(error => {
        commit(CUSTOMER_SUPPORT_FETCH_FAILURE, error)
        throw error
      })
  },
  
  fetchCustomerCorrespondence({ commit }, customer) {
    commit(CUSTOMER_CORRESPONDENCE_FETCH_REQUEST)
    window.axios.get(`/customers/${customer}/correspondence`)
      .then(response => {
        commit(CUSTOMER_CORRESPONDENCE_FETCH_SUCCESS, response)
      }).catch(error => {
        commit(CUSTOMER_CORRESPONDENCE_FETCH_FAILURE, error)
        throw error
      })
  },
  
  fetchCustomerReminders({ commit }, { customer, filter = 'All' }) {
    commit(CUSTOMER_REMINDERS_FETCH_REQUEST)
    window.axios.get(`/customers/${customer}/reminders`, { params: { filter } })
      .then(response => {
        commit(CUSTOMER_REMINDERS_FETCH_SUCCESS, response)
      }).catch(error => {
        commit(CUSTOMER_REMINDERS_FETCH_FAILURE, error)
        throw error
      })
  },
  
  fetchCustomerActivity({ commit }, customer) {
    commit(CUSTOMER_ACTIVITY_FETCH_REQUEST)
    window.axios.get(`/customers/${customer}/activity`)
      .then(response => {
        commit(CUSTOMER_ACTIVITY_FETCH_SUCCESS, response)
      }).catch(error => {
        commit(CUSTOMER_ACTIVITY_FETCH_FAILURE, error)
        throw error
      })
  },

}
