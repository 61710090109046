export const CUSTOMERS_BATCH_DELETE_REQUEST = 'CUSTOMERS_BATCH_DELETE_REQUEST'
export const CUSTOMERS_BATCH_DELETE_SUCCESS = 'CUSTOMERS_BATCH_DELETE_SUCCESS'
export const CUSTOMERS_BATCH_DELETE_FAILURE = 'CUSTOMERS_BATCH_DELETE_FAILURE'

export const CUSTOMERS_FETCH_MANY_REQUEST = 'CUSTOMERS_FETCH_MANY_REQUEST'
export const CUSTOMERS_FETCH_MANY_SUCCESS = 'CUSTOMERS_FETCH_MANY_SUCCESS'
export const CUSTOMERS_FETCH_MANY_FAILURE = 'CUSTOMERS_FETCH_MANY_FAILURE'

export const CUSTOMERS_FETCH_REQUEST = 'CUSTOMERS_FETCH_REQUEST'
export const CUSTOMERS_FETCH_SUCCESS = 'CUSTOMERS_FETCH_SUCCESS'
export const CUSTOMERS_FETCH_FAILURE = 'CUSTOMERS_FETCH_FAILURE'

export const CUSTOMERS_SAVE_REQUEST = 'CUSTOMERS_SAVE_REQUEST'
export const CUSTOMERS_SAVE_SUCCESS = 'CUSTOMERS_SAVE_SUCCESS'
export const CUSTOMERS_SAVE_FAILURE = 'CUSTOMERS_SAVE_FAILURE'

export const CUSTOMER_UPDATE_LAST_CONTACTED_AT = 'CUSTOMER_UPDATE_LAST_CONTACTED_AT'

export const EMPTY_CUSTOMERS = 'EMPTY_CUSTOMERS'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'

export const CUSTOMER_NOTES_FETCH_REQUEST = 'CUSTOMER_NOTES_FETCH_REQUEST'
export const CUSTOMER_NOTES_FETCH_SUCCESS = 'CUSTOMER_NOTES_FETCH_SUCCESS'
export const CUSTOMER_NOTES_FETCH_FAILURE = 'CUSTOMER_NOTES_FETCH_FAILURE'

export const CUSTOMER_ORDERS_FETCH_REQUEST = 'CUSTOMER_ORDERS_FETCH_REQUEST'
export const CUSTOMER_ORDERS_FETCH_SUCCESS = 'CUSTOMER_ORDERS_FETCH_SUCCESS'
export const CUSTOMER_ORDERS_FETCH_FAILURE = 'CUSTOMER_ORDERS_FETCH_FAILURE'

export const CUSTOMER_SUPPORT_FETCH_REQUEST = 'CUSTOMER_SUPPORT_FETCH_REQUEST'
export const CUSTOMER_SUPPORT_FETCH_SUCCESS = 'CUSTOMER_SUPPORT_FETCH_SUCCESS'
export const CUSTOMER_SUPPORT_FETCH_FAILURE = 'CUSTOMER_SUPPORT_FETCH_FAILURE'

export const CUSTOMER_CORRESPONDENCE_FETCH_REQUEST = 'CUSTOMER_CORRESPONDENCE_FETCH_REQUEST'
export const CUSTOMER_CORRESPONDENCE_FETCH_SUCCESS = 'CUSTOMER_CORRESPONDENCE_FETCH_SUCCESS'
export const CUSTOMER_CORRESPONDENCE_FETCH_FAILURE = 'CUSTOMER_CORRESPONDENCE_FETCH_FAILURE'

export const CUSTOMER_REMINDERS_FETCH_REQUEST = 'CUSTOMER_REMINDERS_FETCH_REQUEST'
export const CUSTOMER_REMINDERS_FETCH_SUCCESS = 'CUSTOMER_REMINDERS_FETCH_SUCCESS'
export const CUSTOMER_REMINDERS_FETCH_FAILURE = 'CUSTOMER_REMINDERS_FETCH_FAILURE'

export const CUSTOMER_ACTIVITY_FETCH_REQUEST = 'CUSTOMER_ACTIVITY_FETCH_REQUEST'
export const CUSTOMER_ACTIVITY_FETCH_SUCCESS = 'CUSTOMER_ACTIVITY_FETCH_SUCCESS'
export const CUSTOMER_ACTIVITY_FETCH_FAILURE = 'CUSTOMER_ACTIVITY_FETCH_FAILURE'
